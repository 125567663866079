import Vue from 'vue'
import Vuex from 'vuex'
import apis from "../apis";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    isLoading: false,
    user: {},
    userToken: "",
  },
  mutations: {
    LOGIN: function (state) {
      state.pending = true;

    },
    LOGIN_SUCCESS: function (state, user) {
      state.isLoggedIn = true;
      state.userToken = user.token;
      state.user = user.user;
    },
    LOGOUT: function (state) {

      state.isLoggedIn = false;
      state.userToken = null;
      state.user = {};
    }
  },
  actions: {
    login: function ({ commit }, user) {
      commit("LOGIN_SUCCESS", user);
    },
    logout: function ({ commit }) {
      commit("LOGOUT");
    },
    setMasters: function ({ commit }) {
      let promiseM = apis.readMasters();

      promiseM.then(response => {
        if (response.status == 200) {
          let groups = response.body.Table;
          let topics = response.body.Table1;
          commit("SET_GROUP", groups);

        }
      });

    }
  },
  getters: {
    lockProfile: state => {
      return state.lockProfile;
    },
    isLoggedIn: state => {
      return state.isLoggedIn;
    },
    authToken: state => {
      return state.userToken;
    },
    loggedUser: state => {
      return state.user;
    },
    regType: state => {
      return state.user.user_role;
    },
    submission: state =>{
      return state.user.submission;
    }
  }
})
