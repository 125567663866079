import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

import ApplyJob from '../views/ApplyJob.vue'

import store from '../store'
Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {

  // if (!store.getters.isLoggedIn && this.$session.has("user")) {
  //   this.$store.dispatch("login", {
  //     token: this.$session.get("token"),
  //     user: this.$session.get("user"),
  //   });
  // } else if (!store.getters.isLoggedIn) {

  //   return next('/');;
  // }
  // const { authorize } = to.meta;
  // let current_role = store.getters.regType;
  // // check if route is restricted by role
  // if (authorize.length && !authorize.includes(current_role)) {
  //   // role not authorised so redirect to home page
  //   return next({ path: '/' });
  // }
  next();
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('../views/Gallery.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/profiles',
    name: 'Profiles',
    component: () => import('../views/Profiles.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/newprofile',
    name: 'AddProfile',
    component: () => import('../views/AddProfile.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  
  {
    path: '/applyjob',
    name: 'ApplyJob',
    component: () => import('../views/ApplyJob.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    component: () => import('../views/Contact.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
    meta: { authorize: [] },
    beforeEnter: ifAuthenticated
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
