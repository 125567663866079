import Vue from 'vue'
import VueResource from 'vue-resource';
import store from '../store'

var apibaseurl = "https://irigfquefg.execute-api.ap-south-1.amazonaws.com/Prod/api/" //lambda Prod
var apibaseurlTaluka = "https://t4851d6bfc.execute-api.ap-south-1.amazonaws.com/Prod/api/" //lambda Taluka_search
var apiSaveAppln = "https://3m02edca04.execute-api.ap-south-1.amazonaws.com/Prod/api/";

export default {
  login(user) {
    return Vue.http.post(apibaseurl + 'Login/ValidateUser', user, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  saveProfile(project) {
    return Vue.http.post(apibaseurl + 'Data/addapplication', project, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  saveApplication(appln) {
    return Vue.http.post(apiSaveAppln + 'SaveApplication/SaveApplication', appln, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  readProfiles() {
    return Vue.http.post(apibaseurl + 'Data/applicationlist');
  },
  
  searchTalukas(district) {
    return Vue.http.get(apibaseurlTaluka + 'Masters/SearchTalukas/' + district);
  },
  getSignedURL(payload) {
    return Vue.http.post(apibaseurl + 'PreSignedUrl', payload, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  
  changePassword(user) {
    return Vue.http.post(apibaseurl + 'User/ChangePassword', user, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
}