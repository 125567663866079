<template>
  <div id="app">
    <Navbar></Navbar>
    
    <router-view class="mt-2" />

  </div>
</template>
<script>
import Navbar from "@/components/Navbar";

export default {
  components: { Navbar },
  name: "App",
  methods: {},
  computed: {
    isLoggedIn() {
      if (!this.$store.getters.isLoggedIn && this.$session.has("user")) {
        this.$store.dispatch("login", {
          token: this.$session.get("token"),
          user: this.$session.get("user"),
        });
        return true;
      }
      return this.$store.getters.isLoggedIn;
    },

    RegType() {
      //var nme = this.$store.getters.loggedUser;
      return "0";
    },
  },
};
</script>

<style>
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  background: #e8eff9;
}

#bg{
  background-image: url('@/assets/banner-bg.svg')
}
ul {
  padding: 0;
  margin: 0;
}

.container.sm {
  width: 90% !important;
  background: #2e4b90;
  padding: 20px 10px;
}

body {
  top: 0 !important;
}

@import url("https://fonts.googleapis.com/css?family=Laila&display=swap");

.marathi {
  font-family: "Laila", serif;
}

.header-text h6 {
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 14px;
}

.heading-marathi-2 {
  margin-top: 10px;
}

.header-text {
  margin-top: 10px;
  margin-bottom: 0px;
}

.top-nav-bar {
  /* background-color:#ec5624; */
  /* background-color: #5c6bc0; */
  background-color: #3c37f1;
  margin-bottom: 5px;
  /* background-image: url("assets/images/circles.png"); */
}

#top-text {
  font-size: 15px;
  text-align: center;
  padding-top: 7px;
  color: #fff;
}


/* responsive styles */

@media (max-width: 1199px) {
  #top-nav-header .photo-box-wrap .content-box h5 {
    font-size: 12px;
  }

  #top-nav-header .photo-box-wrap .content-box p {
    font-size: 10px;
  }
}

@media (max-width: 991px) {
  #top-nav-header .hide-tab {
    display: none;
  }
}

@media (max-width: 767px) {
  #top-text {
    text-align: left;
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  #top-text {
    padding-top: 32px;
    text-align: center;
  }
}

.footer-main {
  margin-top: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #3c37f1;
  text-align: right;
  bottom: 0;
  width: 100%;
}

.footer-main ul {
  width: fit-content;
  margin: auto;
}

.text-sm {
  display: none;
}

@media (max-width: 767px) {
  .text-sm {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  #top-nav-header .logo-box img {
    max-width: 75px;
    max-height: 75px;
  }
}

@media (max-width: 419px) {
  .text-sm {
    font-size: 14px;
    line-height: 1.4;
  }

  #top-nav-header .logo-box img {
    max-width: 65px;
    max-height: 60px;
  }
}
</style>

