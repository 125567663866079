<template>
  <b-container fluid style="background-color: #fff">
    <b-row>
      <b-col>
        <div class="login-wrapper">
          <div class="login-box-inner">
            <h6>Login</h6>
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem" />
            <b-row>
              <b-col>
                <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                  <b-alert
                    :show="showDismissibleAlert"
                    dismissible
                    @dismissed="showDismissibleAlert = false"
                    :variant="variant"
                  >
                    {{ alertText }}
                  </b-alert>
                  <div class="form-group">
                    <small for="exampleInputEmail1">Login As</small>
                    <b-select
                      type="text"
                      :formatter="format"
                      lazy-formatter
                      v-model="form.user_role"
                      required
                      class="form-control"
                      size="sm"
                      id="inputUserRole"
                      :options="roles"
                    ></b-select>
                  </div>

                  <div class="form-group">
                    <small for="exampleInputEmail1">User Name</small>
                    <b-input
                      type="email"
                      :formatter="format"
                      lazy-formatter
                      v-model="form.user_name"
                      required
                      class="form-control"
                      size="sm"
                      id="inputUserID"
                      placeholder="Enter Your UserName"
                    ></b-input>
                  </div>

                  <div class="form-group">
                    <small for="exampleInputEmail1">Password</small>
                    <b-input
                      type="password"
                      :formatter="format"
                      lazy-formatter
                      v-model="form.password"
                      class="form-control"
                      size="sm"
                      id="inputPwd"
                      placeholder="Enter Password"
                      maxlength="20"
                    ></b-input>
                  </div>
                  <div>
                    <button
                      class="btn btn-success btn-sm float-right"
                      type="submit"
                    >
                      Login
                    </button>
                    <b-spinner
                      variant="success"
                      label="Loading.."
                      v-if="isLoading"
                    ></b-spinner>
                  </div>
                  <div>
                    <b-btn
                      variant="link"
                      size="sm"
                      class="float-right"
                      @click="showModal"
                      >Forgot Password</b-btn
                    >
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <footer style="margin-bottom: 0">
      <div class="row" style="background-color: #2e4b90; color: white">
        <div class="col" style="padding-left: 25px; padding-right: 5px">
          <p class="float-left" style="font-size: 0.75rem; margin: 0">
            Copyright © 2022 State Institute of Science Education. All Rights
            Reserved.
          </p>
        </div>
        <div class="col" style="padding-right: 25px">
          <p class="float-right" style="font-size: 0.75rem; margin: 0">
            Web Portal Designed & Developed by
            <a href="www.beismer.com" style="color: yellow">SUN INFOTECH</a>
          </p>
        </div>
      </div>
      <div class="row" style="background-color: #2e4b90; color: white">
        <div class="col">
          <p class="text-center" style="font-size: 0.75rem; margin: 0">
            Web Portal Contents and Data Provided & Maintained by Department of
            Education and Sports, Government of Maharashtra
          </p>
        </div>
      </div>
      <div class="row" style="background-color: #2e4b90; color: white">
        <div class="col">
          <p class="text-center" style="font-size: 0.75rem; margin: 0">
            Best Viewed in IE-9 and Above, Google Chrome and Mozilla Firefox
          </p>
        </div>
      </div>
    </footer>
  </b-container>
</template>

<script>
import apis from "../apis";
export default {
  data() {
    return {
      dismissSecs: 20,
      dismissCountDown: 0,
      form: {
        user_name: "",
        password: "",
        user_role: 1,
      },
      roles: [
        { value: 1, text: "Admin" },
        { value: 2, text: "Edu Officer" },
        { value: 3, text: "Judge" },
        { value: 4, text: "Guest" },
      ],
      slide: 0,
      sliding: null,
      show: true,
      alertText: "",
      showDismissibleAlert: false,
      variant: "success",
      isLoading: false,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    format(value, event) {
      if (value) {
        return value.trim();
      }
    },
    showModal() {
      this.$refs.myModalRef.show();
    },
    hideModal() {
      this.$refs.myModalRef.hide();
    },

    onSubmit(evt) {
      evt.preventDefault();
      this.isLoading = true;
      this.showDismissibleAlert = false;

      let promise = apis.login(this.form);

      promise
        .then((response) => {
          if (response.status == 200) {
            let userary = response.body;
            if (userary.length == 0) {
              this.alertText = "User name or Password is invalid";

              this.variant = "warning";
              this.showDismissibleAlert = true;
            } else {
              this.$store.dispatch("login", {
                token: response.body.token,
                user: response.body,
              });

              this.$session.start();
              this.$session.set("token", response.body.token);
              this.$session.set("user", response.body);

              if (response.body.change_password) {
                this.$router.replace("/changepassword");
              } else {
                this.$router.replace("/dashboard");
              }
            }
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.body;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });
    },
    onReset(evt) {},
  },
  mounted() {
    this.dismissCountDown = this.dismissSecs;
  },
};
</script>

<style scoped>
.login-wrapper {
  margin: auto;
  max-width: 400px;
  margin-bottom: 20px;
}

.login-box-inner {
  background: #fbfbfb;
  padding: 25px 20px;
  border: solid thin #ddd;
  border-radius: 4px;
  -webkit-box-shadow: 8px 14px 14px -4px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 8px 14px 14px -4px rgba(0, 0, 0, 0.32);
  box-shadow: 8px 14px 14px -4px rgba(0, 0, 0, 0.32);
}

@media (max-width: 420px) {
  .login-wrapper {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>