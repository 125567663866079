<template>
  <b-container>
    <div
      class="row"
      style="
        align: center;
        margin-top: 10px;
        border: solid #e4762c;
        border-radius: 20px;
      "
    >
      <p
        style="
          font-size: 1.3em;
          color: #e4762c;
          margin-top: 10px;
          text-align: center;
          width: 100%;
        "
      >
        जिल्हातील युवकांसाठी रोजगाराच्या संधी
      </p>
      <p style="font-size: 1em; color: black; text-align: center; width: 100%">
        बुलढाणा जिल्हातील युवक व युवतीं साठी आम्ही रोजगाराच्या संधी घेऊन आलो
        आहोत. तरी पात्र उमेदवारांना विनंती आहे की त्यानीं आपली पसंती व आपला
        Bio-data भरून द्यावा.
      </p>
      
      <p
        style="font-size: 1.3em; color: black; text-align: center; width: 100%"
      >
        <button
          class="btn btn-suceess"
          v-b-modal.modal-1
          style="background: #15569b; color: white; width: 20%"
        >
          Apply
        </button>
      </p>
      <small style="text-align: center;width: 100%">तांत्रिक अडचणी सदर्भात कृपया 9112244599 या क्रमांकावर संपर्क साधावा</small>
    </div>
    <div class="row mt-3">
      <div class="col-md-7 wrap-about">
        <div class="heading-section pr-md-5">
          <span class="subheading"
            >श्रीराम कुटे गुरुजी चॅरिटेबल ट्रस्ट तर्फे आपले स्वागत</span
          >
          <h2 class="mb-4">आम्ही आहोत, प्रत्येक गरजूच्या मदती साठी</h2>
          <p>
            श्रीराम कुटे गुरुजी चॅरिटेबल ट्रस्ट, सामाजिक, सांस्कृतिक, शैक्षणिक
            अशा विविध क्षेत्रांमध्ये कार्यरत आहे. या ट्रस्ट मार्फत
            विद्यार्थ्यांना शालेय तशीच आरोग्य मदत केली जाते. ट्रस्ट मार्फत
            कुपोषित बालकांना दत्तक घेणे, वृद्ध नागरिकणा चष्मे वाटप करणे, आरोग्य 
            शिबिर भरविणे अशी अनेक कामे केली जातात. बेरोजगार युवक व युवतींसाठी
            ट्रस्ट मार्फत रोजगाराच्या संधि उपलब्ध करून दिल्या जातात.
          </p>
        </div>
        <div class="row my-md-5">
          <div class="col-md-6 d-flex counter-wrap">
            <div class="block-18 d-flex">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="flaticon-volunteer"></span>
              </div>
              <div class="desc">
                <div class="text">
                  <strong class="number" data-number="50">50</strong>
                </div>
                <div class="text">
                  <span>स्वयंसेवक</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex counter-wrap">
            <div class="block-18 d-flex">
              <div
                class="icon d-flex align-items-center justify-content-center"
              >
                <span class="flaticon-piggy-bank"></span>
              </div>
              <div>
                <div class="text">
                  <strong class="number">54,400</strong>
                </div>
                <div class="text">
                  <span>लाभार्थी</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5 d-flex px-5">
        <div
          class="info-wrap w-100 p-5 img"
          style="background-image: url(/about-us-1.jpeg)"
        ></div>
      </div>
    </div>

    <b-modal id="modal-1" size="lg">
      <survey :survey="survey"></survey>
      <template #modal-footer>
        <div class="w-100"></div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import * as SurveyVue from "survey-vue";
import { StylesManager } from "survey-vue";
var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";

import apis from "@/apis";
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      survey: new SurveyVue.Model({}),
    };
  },
  components: {
    Survey,
    StylesManager,
  },
  mounted() {
    let surveyJSON = {
      title: "APPLY FOR JOB",
      logoPosition: "right",
      completedHtml:
        "<h3>Thank you for Providing Details. We will send an SMS toyou shortly.</h3>",
      completedBeforeHtml:
        "<h3>Our records show that you have already submitted details.</h3>",
      loadingHtml: "<h3>Loading...</h3>",
      pages: [
        {
          name: "page3",
          elements: [
            {
              type: "dropdown",
              name: "job_choice",
              title: "Please Select Job You are Interested In",
              isRequired: true,
              choices: [
                "Asst Teacher std. I to V (सहा. शिक्षक)",
                "Asst Teacher std. VI to VIII (सहा. शिक्षक)",
                "Asst Teacher std. IX - English (सहा. शिक्षक)",
                "Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)",
                "Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)",
                "Karyanubhav Teacher (कार्याशिक्षण / कार्यानुभव शिक्षक)",
                {
                  value: "Art and Craft Teacher (कला शिक्षक)",
                  text: "Art Teacher (कला शिक्षक - चित्रकला)",
                },
                "Lab. Assistance (प्रयोगशाळा सहाय्यक)",
                "Health and Physical Education Teacher Director (आरोग्य व शारीरिक शिक्षक निर्देशक)",
                "Computer Teacher (संगणक शिक्षक)",
                "House Keeper (गृहपाल)",
                "Nurse (परिचारिका)",
                "Medical Officer (वैद्यकीय अधिकारी)",
                "Care Taker (काळजीवाहक)",
                "Washerman (धोबी)",
                "Data Entry Operator (डाटा एंट्री )",
                "Clerk (कारकून)",
                "Other (इतर)",
              ],
            },
            {
              type: "html",
              name: "desc_1",
              visibleIf:
                "{job_choice} = 'Asst Teacher std. I to V (सहा. शिक्षक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>HSC D.Ed</li>\n<li> कमीत कमी ३ वर्षाचा अनुभव</li>\n<li>TET उत्तीर्ण </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc _2",
              visibleIf:
                "{job_choice} = 'Asst Teacher std. VI to VIII (सहा. शिक्षक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>डी एड/ बी एड  + पदवी (विषयासह)  </li>\n<li> टी ई टी / टीएफआयटी  उत्तीर्ण </li>\n<li>भाषा, सामान्यशास्त्र विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_3",
              visibleIf:
                "{job_choice} = 'Asst Teacher std. IX - English (सहा. शिक्षक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>बी एड  + पदवी (विषयासह)  </li>\n<li>कमीत कमी ३ वर्षाचा अनुभव </li>\n<li> टीएफआयटी  उत्तीर्ण </li>\n<li>इंग्रजी /समाज शास्त्र विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_4",
              visibleIf:
                "{job_choice} = 'Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>बी एड  + पदवी (विषयासह)  </li>\n<li>कमीत कमी ३ वर्षाचा अनुभव </li>\n<li> टीएफआयटी  उत्तीर्ण </li>\n<li>गणित /विज्ञान विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_5",
              visibleIf:
                "{job_choice} = 'Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>बी एड  + पदवी (विषयासह)  </li>\n<li>कमीत कमी ३ वर्षाचा अनुभव </li>\n<li> टीएफआयटी  उत्तीर्ण </li>\n<li>हिन्दी / समाजशास्त्र विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_6",
              visibleIf:
                "{job_choice} anyof ['Karyanubhav Teacher (कार्याशिक्षण / कार्यानुभव शिक्षक)', 'Art and Craft Teacher (कला शिक्षक)']",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>जी डी आर्ट / ए टी डी सी किंवा आर्ट मास्टर प्रमाणपत्र / पदविका / कला शिक्षण शास्त्र पदविका राष्ट्रीय किंवा आंतरराष्ट्रीय पातळी   </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_7",
              visibleIf:
                "{job_choice} = 'Lab. Assistance (प्रयोगशाळा सहाय्यक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>१२ वी विज्ञान / पदवी धारक </li>\n<li>शासकीय / निमशासकीय / खाजगी संस्था /शाळा मधून प्रयोगशाळा सहाय्यक म्हणून २ वर्षाचा अनुभव असल्यास प्राधान्य </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_8",
              visibleIf:
                "{job_choice} = 'Health and Physical Education Teacher Director (आरोग्य व शारीरिक शिक्षक निर्देशक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>शारीरिक शिक्षण विषयातील पदवी उत्तीर्ण </li>\n<li>शासकीय / निमशासकीय / खाजगी संस्था /शाळा मधून प्रयोगशाळा सहाय्यक म्हणून २ वर्षाचा अनुभव असल्यास प्राधान्य </li>\n<li>राष्ट्रीय व आंतरराष्ट्रीय स्तरावरील खेळाडू </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_9",
              visibleIf: "{job_choice} = 'Computer Teacher (संगणक शिक्षक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>संगणकीय  पदवी उत्तीर्ण </li>\n<li>शासकीय / निमशासकीय / खाजगी संस्थेतील संगकण शिक्षक म्हणून २ वर्षाचा अनुभव </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_10",
              visibleIf: "{job_choice} = 'House Keeper (गृहपाल)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>कोणत्याही शाखेची पदवी उत्तीर्ण </li>\n<li>बी एड / डी एड  पदवी उत्तीर्ण </li>\n<li>एम एस सी आय टी / डी ओ ए सी सी प्रमाण पत्र  </li>\n<li>Hospitality Management </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_11",
              visibleIf: "{job_choice} = 'Nurse (परिचारिका)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>नर्सिंगचा G.N.M. कोर्स पूर्ण केलेला असावा </li>\n<li>महाराष्ट्र नर्सिंग कौन्सिल कडे रजिस्ट्रेशन आवश्यक  </li>\n<li>Hospitality Management </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_12",
              visibleIf: "{job_choice} = 'Medical Officer (वैद्यकीय अधिकारी)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>M.B.B.S. उत्तीर्ण  </li>\n<li>वैद्यकीय क्षेत्रातील 3 वर्षाचा अनुभव </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_13",
              visibleIf: "{job_choice} = 'Care Taker (काळजीवाहक)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>७ वी उत्तीर्ण  </li>\n<li>प्रथमोपचार या कामचा अनुभव असल्यास प्राधान्य </li>\n</ol>\n</div>',
            },
            {
              type: "html",
              name: "desc_14",
              visibleIf: "{job_choice} = 'Washerman (धोबी)'",
              html: '<div style="color:red">\n<p>Job Qualification</p>\n<ol>\n<li>कमीत कमी ८ वी उत्तीर्ण  </li>\n<li>धोबी कामाचा अनुभव असल्यास प्राधान्य </li>\n</ol>\n</div>',
            },
          ],
          title: "Applying for Job:",
        },
        {
          name: "page1",
          elements: [
            {
              type: "text",
              name: "candidate_name",
              title: "Your Full Name",
              isRequired: true,
              placeholder: "Name MiddleName Surname",
            },
            {
              type: "text",
              name: "candidate_dob",
              title: "Date of Birth",
              isRequired: true,
              inputType: "date",
            },
            {
              type: "comment",
              name: "Candidate Address",
              title: "Address",
              isRequired: true,
              rows: 3,
              placeholder: "Provide your address",
            },
            {
              type: "dropdown",
              name: "candidate_state",
              title: "State",
              isRequired: true,
              choicesByUrl: {
                url: "https://d2v8797bj7.execute-api.ap-south-1.amazonaws.com/Prod/api/Masters/ReadAllState",
                valueName: "StateName",
                titleName: "StateName",
              },
            },
            {
              type: "dropdown",
              name: "candidate_district",
              startWithNewLine: false,
              title: "District",
              isRequired: true,
              choicesByUrl: {
                url: "https://d2v8797bj7.execute-api.ap-south-1.amazonaws.com/Prod/api/Masters/ReadDistrictByStateName/{candidate_state}",
                valueName: "DistrictName",
                titleName: "DistrictName",
              },
            },
            {
              type: "text",
              name: "candidate_taluka",
              title: "Taluka",
              isRequired: true,
            },
            {
              type: "text",
              name: "candidate_pin",
              title: "PIN Code",
              isRequired: true,
              inputType: "number",
              min: 100000,
              max: 999999,
              step: 1,
            },
            {
              type: "text",
              name: "candicate_mobile",
              startWithNewLine: false,
              title: "Mobile No ",
              inputType: "number",
              min: 1000000000,
              max: 9999999999,
              step: 1,
            },
            {
              type: "text",
              name: "candidate_email",
              title: "Email  ID ",
              inputType: "email",
            },
          ],
          title: "Personal Details",
        },
        {
          name: "page2",
          elements: [
            {
              type: "dropdown",
              name: "candidate_qualification",
              title: "Educational Qualification",
              choices: [
                "Post Graduate",
                "Graduate",
                "Diploma",
                "HSC",
                "HSC Fail",
                "SSC",
                "SSC Fail",
                "8th",
                "5th-7th",
                "Illiterate",
              ],
            },
            {
              type: "text",
              name: "candidate_degree",
              visibleIf:
                "{candidate_qualification} anyof ['Post Graduate', 'Graduate', 'Diploma', 'HSC']",
              title: "Degree Details",
              description: "B.Com, BSC,BA etc.",
              isRequired: true,
            },
            {
              type: "text",
              name: "candidate_marks",
              startWithNewLine: false,
              title: "% Marks Obtained ",
              description: "Percentage Marks obtained in Final Exam",
              isRequired: true,
              inputType: "number",
              min: 0,
              max: 100,
              step: 0.1,
            },
            {
              type: "file",
              name: "edu_certificate_path",
              title: "Education Certificate",
              description:
                "Only Upload your Final Education Certificate in PDF format size max 5mb",
              isRequired: true,
              imageHeight: "100px",
              imageWidth: "100px",
              acceptedTypes: "pdf",
              storeDataAsText: false,
              waitForUpload: true,
            },
            {
              type: "text",
              name: "q_marathi_type",
              visibleIf:
                "{job_choice} anyof ['Data Entry Operator (डाटा एंट्री )', 'Clerk (कारकून)', 'Other (इतर)']",
              title: "Typing Speed Marathi",
              inputType: "number",
            },
            {
              type: "text",
              name: "q_type_Eng",
              visibleIf:
                "{job_choice} anyof ['Data Entry Operator (डाटा एंट्री )', 'Clerk (कारकून)', 'Other (इतर)']",
              title: "Typing Speed English",
              inputType: "number",
            },
          ],
          title: "Education Details",
        },
        {
          name: "page4",
          elements: [
            {
              type: "file",
              name: "bed_certificate_doc",
              visibleIf:
                "{job_choice} anyof ['Asst Teacher std. I to V (सहा. शिक्षक)', 'Asst Teacher std. VI to VIII (सहा. शिक्षक)', 'Asst Teacher std. IX - English (सहा. शिक्षक)', 'Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)', 'Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)', 'House Keeper (गृहपाल)']",
              title: "Upload B.Ed / D. Ed certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "tet_certificate_doc",
              visibleIf:
                "{job_choice} anyof ['Asst Teacher std. I to V (सहा. शिक्षक)', 'Asst Teacher std. VI to VIII (सहा. शिक्षक)']",
              title: "Upload TET Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "question5",
              visibleIf:
                "{job_choice} anyof ['Asst Teacher std. IX - English (सहा. शिक्षक)', 'Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)', 'Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)']",
              title: "Upload TFIT Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "mscit_doc",
              visibleIf: "{job_choice} = 'House Keeper (गृहपाल)'",
              title: "Upload MSCIT / MSOCC Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "art_teacher_doc",
              visibleIf:
                "{job_choice} anyof ['Karyanubhav Teacher (कार्याशिक्षण / कार्यानुभव शिक्षक)', 'Art and Craft Teacher (कला शिक्षक)']",
              title: "Upload GD Art / ATDC TC / Art Master  Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "nursing_registration_doc",
              visibleIf: "{job_choice} = 'Nurse (परिचारिका)'",
              title: "Upload Nursing Registration  Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "exp_certificate_doc",
              title: "Upload Experience Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
            {
              type: "file",
              name: "typing_certificate",
              visibleIf:
                "{job_choice} anyof ['Data Entry Operator (डाटा एंट्री )', 'Clerk (कारकून)', 'Other (इतर)']",
              title: "Upload Typing Certificate",
              isRequired: true,
              storeDataAsText: false,
            },
          ],
          title: "Qualifications Certificates",
        },
      ],
      showNavigationButtons: "both",
      showTitle: false,
      showPageNumbers: true,
    };

    this.survey = new SurveyVue.Model(surveyJSON);
    this.survey.cssType = "bootstrap";
    var myCss = {
      matrix: {
        root: "table table-striped",
      },
      navigationButton: "button btn-sm btn-primary m-1",
      title: "font-weight-normal text-lg",
      svd_container: "font-size:6px",
    };

    this.survey.css = myCss;

    this.survey.onUploadFiles.add((survey, options) => {
      let d = new Date();
      var filetype = options.files[0].type;
      var filepath = options.name + "_" + d * 1 + "_" + options.files[0].name;

      let payload = {
        FilePath: filepath,
        ContentType: filetype,
      };

      let promiseImage = apis.getSignedURL(payload);
      promiseImage
        .then((response) => {
          let signedUrl = response.body.url;
          var upldoptions = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": options.files[0].type,
            },
          };
          return axios.put(signedUrl, options.files[0], upldoptions);
        })
        .then((res) => {
          if (res.status == 200) {
            options.callback(
              "success",
              options.files.map((file) => {
                return {
                  file: file,
                  //content: data[file.name]
                  content:
                    "https://s3.ap-south-1.amazonaws.com/shriram.guruji.images/" +
                    filepath,
                };
              })
            );
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";

          this.isLoading = false;
        });
    });

    this.survey.onComplete.add((survey, options) => {
      var promise = apis.saveApplication({
        application_details: JSON.stringify(survey.data),
      });

      promise
        .then((response) => {
          if (response.status == 200) {
            //this.form.id = response.body[0].id;
            this.$dialog.alert("Saved Succesfully..");
            this.reset();
          } else {
            this.$dialog.alert(
              "Error While Saving Data. Please Contact Site Admin."
            );
          }
          this.isSaving = false;
        })
        .catch((err) => {
          // console.error(err);
          survey.clear(false);
          survey.currentPage = 0;
          this.$dialog.alert("Error. Please Contact Site Admin");
          this.isSaving = false;
        });
    });
  },
};
</script>
<style scoped>
.svd_container {
  font-size: 6px !important;
}
.heading-section .subheading {
  font-size: 14px;
  display: block;
  font-weight: 800;
  color: #000000;
  text-transform: uppercase;
  /* letter-spacing: 2px; */
}
.heading-section h2 {
  font-size: 30px;
  font-weight: 800;
  color: #e4762c;
  /* line-height: 1.3; */
  margin-top: 5px;
}
.img,
.blog-img,
.user-img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.wrap-about .block-18 .desc {
  width: calc(100% - 80px);
  margin: 0 0 0 auto;
}
.wrap-about .text .number {
  line-height: 1;
  font-weight: 700;
  font-size: 30px;
  color: #e4762c;
  display: block;
  margin-bottom: 4px;
}
</style>