<template>
          <survey :survey="survey"></survey>
</template>

<script>
import * as SurveyVue from "survey-vue";
var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";

export default {
  name: 'Home',
  data() {
    return {
      survey: new SurveyVue.Model({}),
    }
  },
  components: {
    Survey
  },
  mounted(){
    let surveyJSON = {
 "title": "APPLY FOR JOB",
 "logoPosition": "right",
 "pages": [
  {
   "name": "page3",
   "elements": [
    {
     "type": "dropdown",
     "name": "job_choice",
     "title": "Please Select Job You are Interested In",
     "isRequired": true,
     "choices": [
      "Asst Teacher std. I to V (सहा. शिक्षक)",
      "Asst Teacher std. VI to VIII (सहा. शिक्षक)",
      "Asst Teacher std. IX - English (सहा. शिक्षक)",
      "Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)",
      "Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)",
      "Karyanubhav Teacher (कार्याशिक्षण / कार्यानुभव शिक्षक)",
      {
       "value": "Art and Craft Teacher (कला शिक्षक)",
       "text": "Art Teacher (कला शिक्षक - चित्रकला)"
      },
      "Lab. Assistance (प्रयोगशाळा सहाय्यक)",
      "Health and Physical Education Teacher Director (आरोग्य व शारीरिक शिक्षक निर्देशक)",
      "Computer Teacher (संगणक शिक्षक)",
      "House Keeper (गृहपाल)",
      "Nurse (परिचारिका)",
      "Medical Officer (वैद्यकीय अधिकारी)",
      "Case Taker (काळजीवाहक)",
      "Washerman (धोबी)"
     ]
    },
    {
     "type": "html",
     "name": "desc_1",
     "visibleIf": "{job_choice} = 'Asst Teacher std. I to V (सहा. शिक्षक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>HSC D.Ed</li>\n<li> कमीत कमी ३ वर्षाचा अनुभव</li>\n<li>TET उत्तीर्ण </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc _2",
     "visibleIf": "{job_choice} = 'Asst Teacher std. VI to VIII (सहा. शिक्षक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>डी एड/ बी एड  + पदवी (विषयासह)  </li>\n<li> टी ई टी / टीएफआयटी  उत्तीर्ण </li>\n<li>भाषा, सामान्यशास्त्र विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_3",
     "visibleIf": "{job_choice} = 'Asst Teacher std. IX - English (सहा. शिक्षक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>बी एड  + पदवी (विषयासह)  </li>\n<li>कमीत कमी ३ वर्षाचा अनुभव </li>\n<li> टीएफआयटी  उत्तीर्ण </li>\n<li>इंग्रजी /समाज शास्त्र विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_4",
     "visibleIf": "{job_choice} = 'Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>बी एड  + पदवी (विषयासह)  </li>\n<li>कमीत कमी ३ वर्षाचा अनुभव </li>\n<li> टीएफआयटी  उत्तीर्ण </li>\n<li>गणित /विज्ञान विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_5",
     "visibleIf": "{job_choice} = 'Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>बी एड  + पदवी (विषयासह)  </li>\n<li>कमीत कमी ३ वर्षाचा अनुभव </li>\n<li> टीएफआयटी  उत्तीर्ण </li>\n<li>हिन्दी / समाजशास्त्र विषयासह </li>\n<li>आर टी ई नियमांनुसार </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_6",
     "visibleIf": "{job_choice} anyof ['Karyanubhav Teacher (कार्याशिक्षण / कार्यानुभव शिक्षक)', 'Art and Craft Teacher (कला शिक्षक)']",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>जी डी आर्ट / ए टी डी सी किंवा आर्ट मास्टर प्रमाणपत्र / पदविका / कला शिक्षण शास्त्र पदविका राष्ट्रीय किंवा आंतरराष्ट्रीय पातळी   </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_7",
     "visibleIf": "{job_choice} = 'Lab. Assistance (प्रयोगशाळा सहाय्यक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>१२ वी विज्ञान / पदवी धारक </li>\n<li>शासकीय / निमशासकीय / खाजगी संस्था /शाळा मधून प्रयोगशाळा सहाय्यक म्हणून २ वर्षाचा अनुभव असल्यास प्राधान्य </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_8",
     "visibleIf": "{job_choice} = 'Lab. Assistance (प्रयोगशाळा सहाय्यक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>शारीरिक शिक्षण विषयातील पदवी उत्तीर्ण </li>\n<li>शासकीय / निमशासकीय / खाजगी संस्था /शाळा मधून प्रयोगशाळा सहाय्यक म्हणून २ वर्षाचा अनुभव असल्यास प्राधान्य </li>\n<li>राष्ट्रीय व आंतरराष्ट्रीय स्तरावरील खेळाडू </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_9",
     "visibleIf": "{job_choice} = 'Computer Teacher (संगणक शिक्षक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>संगणकीय  पदवी उत्तीर्ण </li>\n<li>शासकीय / निमशासकीय / खाजगी संस्थेतील संगकण शिक्षक म्हणून २ वर्षाचा अनुभव </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_10",
     "visibleIf": "{job_choice} = 'House Keeper (गृहपाल)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>कोणत्याही शाखेची पदवी उत्तीर्ण </li>\n<li>बी एड / डी एड  पदवी उत्तीर्ण </li>\n<li>एम एस सी आय टी / डी ओ ए सी सी प्रमाण पत्र  </li>\n<li>Hospitality Management </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_11",
     "visibleIf": "{job_choice} = 'Nurse (परिचारिका)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>नर्सिंगचा G.N.M. कोर्स पूर्ण केलेला असावा </li>\n<li>महाराष्ट्र नर्सिंग कौन्सिल कडे रजिस्ट्रेशन आवश्यक  </li>\n<li>Hospitality Management </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_12",
     "visibleIf": "{job_choice} = 'Medical Officer (वैद्यकीय अधिकारी)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>M.B.B.S. उत्तीर्ण  </li>\n<li>वैद्यकीय क्षेत्रातील 3 वर्षाचा अनुभव </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_13",
     "visibleIf": "{job_choice} = 'Case Taker (काळजीवाहक)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>७ वी उत्तीर्ण  </li>\n<li>प्रथमोपचार या कामचा अनुभव असल्यास प्राधान्य </li>\n</ol>\n</div>"
    },
    {
     "type": "html",
     "name": "desc_14",
     "visibleIf": "{job_choice} = 'Washerman (धोबी)'",
     "html": "<div style=\"color:red\">\n<p>Job Qualification</p>\n<ol>\n<li>कमीत कमी ८ वी उत्तीर्ण  </li>\n<li>धोबी कामाचा अनुभव असल्यास प्राधान्य </li>\n</ol>\n</div>"
    }
   ],
   "title": "Applying for Job:"
  },
  {
   "name": "page1",
   "elements": [
    {
     "type": "text",
     "name": "candidate_name",
     "title": "Your Full Name",
     "isRequired": true,
     "placeholder": "Name MiddleName Surname"
    },
    {
     "type": "text",
     "name": "candidate_dob",
     "title": "Date of Birth",
     "isRequired": true,
     "inputType": "date"
    },
    {
     "type": "comment",
     "name": "Candidate Address",
     "title": "Address",
     "isRequired": true,
     "rows": 3,
     "placeholder": "Provide your address"
    },
    {
     "type": "dropdown",
     "name": "candidate_state",
     "title": "State",
     "isRequired": true,
     "choicesByUrl": {
      "url": "https://d2v8797bj7.execute-api.ap-south-1.amazonaws.com/Prod/api/Masters/ReadAllState",
      "valueName": "StateName",
      "titleName": "StateName"
     }
    },
    {
     "type": "dropdown",
     "name": "candidate_district",
     "startWithNewLine": false,
     "title": "District",
     "isRequired": true,
     "choicesByUrl": {
      "url": "https://d2v8797bj7.execute-api.ap-south-1.amazonaws.com/Prod/api/Masters/ReadDistrictByStateName/{candidate_state}",
      "valueName": "DistrictName",
      "titleName": "DistrictName"
     }
    },
    {
     "type": "text",
     "name": "candidate_taluka",
     "title": "Taluka",
     "isRequired": true
    },
    {
     "type": "text",
     "name": "candidate_pin",
     "title": "PIN Code",
     "isRequired": true,
     "inputType": "number",
     "min": 100000,
     "max": 999999,
     "step": 1
    },
    {
     "type": "text",
     "name": "candicate_mobile",
     "startWithNewLine": false,
     "title": "Mobile No ",
     "inputType": "number",
     "min": 1000000000,
     "max": 9999999999,
     "step": 1
    },
    {
     "type": "text",
     "name": "candidate_email",
     "title": "Email  ID ",
     "inputType": "email"
    }
   ],
   "title": "Personal Details"
  },
  {
   "name": "page2",
   "elements": [
    {
     "type": "dropdown",
     "name": "candidate_qualification",
     "title": "Educational Qualification",
     "choices": [
      "Post Graduate",
      "Graduate",
      "Diploma",
      "HSC",
      "HSC Fail",
      "SSC",
      "SSC Fail",
      "8th",
      "5th-7th",
      "Illiterate"
     ]
    },
    {
     "type": "text",
     "name": "candidate_degree",
     "visibleIf": "{candidate_qualification} anyof ['Post Graduate', 'Graduate', 'Diploma', 'HSC']",
     "title": "Degree Details",
     "description": "B.Com, BSC,BA etc.",
     "isRequired": true
    },
    {
     "type": "text",
     "name": "candidate_marks",
     "startWithNewLine": false,
     "title": "% Marks Obtained ",
     "description": "Percentage Marks obtained in Final Exam",
     "isRequired": true,
     "inputType": "number",
     "min": 0,
     "max": 100,
     "step": 0.1
    },
    {
     "type": "file",
     "name": "edu_certificate_path",
     "title": "Education Certificate",
     "description": "Only Upload your Final Education Certificate in PDF format size max 5mb",
     "isRequired": true,
     "imageHeight": "100px",
     "imageWidth": "100px",
     "acceptedTypes": "pdf",
     "waitForUpload": true
    }
   ],
   "title": "Education Details"
  },
  {
   "name": "page4",
   "elements": [
    {
     "type": "file",
     "name": "bed_certificate_doc",
     "visibleIf": "{job_choice} anyof ['Asst Teacher std. I to V (सहा. शिक्षक)', 'Asst Teacher std. VI to VIII (सहा. शिक्षक)', 'Asst Teacher std. IX - English (सहा. शिक्षक)', 'Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)', 'Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)', 'House Keeper (गृहपाल)']",
     "title": "Upload B.Ed / D. Ed certificate",
	 "isRequired": true
    },
    {
     "type": "file",
     "name": "tet_certificate_doc",
     "visibleIf": "{job_choice} anyof ['Asst Teacher std. I to V (सहा. शिक्षक)', 'Asst Teacher std. VI to VIII (सहा. शिक्षक)']",
     "title": "Upload TET Certificate",
	 "isRequired": true
    },
    {
     "type": "file",
     "name": "question5",
     "visibleIf": "{job_choice} anyof ['Asst Teacher std. IX - English (सहा. शिक्षक)', 'Asst Teacher std. IX - Maths /Science (सहा. शिक्षक)', 'Asst Teacher std. IX - Hindi / Social Science (सहा. शिक्षक)']",
     "title": "Upload TFIT Certificate",
	 "isRequired": true
    },
    {
     "type": "file",
     "name": "mscit_doc",
     "visibleIf": "{job_choice} = 'House Keeper (गृहपाल)'",
     "title": "Upload MSCIT / MSOCC Certificate",
	 "isRequired": true
    },
    {
     "type": "file",
     "name": "art_teacher_doc",
     "visibleIf": "{job_choice} anyof ['Karyanubhav Teacher (कार्याशिक्षण / कार्यानुभव शिक्षक)', 'Art and Craft Teacher (कला शिक्षक)']",
     "title": "Upload GD Art / ATDC TC / Art Master  Certificate",
	 "isRequired": true
    },
    {
     "type": "file",
     "name": "nursing_registration_doc",
     "visibleIf": "{job_choice} = 'Nurse (परिचारिका)'",
     "title": "Upload Nursing Registration  Certificate",
     "isRequired": true
    },
    {
     "type": "file",
     "name": "exp_certificate_doc",
     "title": "Upload Experience Certificate",
     "isRequired": true
    }
   ],
   "title": "Qualifications Certificates"
  }
 ]
};

    this.survey = new SurveyVue.Model(surveyJSON);
        this.survey.cssType = "bootstrap";

  }
}
</script>
