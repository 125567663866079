<template>
  <div class="py-0" style="right: 0; left: 0">
        <div class="wrap">
      <div class="container">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <!-- <p class="mb-0 phone pl-md-2">
              <a href="#" class="mr-2"
                ><span class="fa fa-phone mr-1"></span> +00 1234 567</a
              >
              <a href="#"
                ><span class="fa fa-paper-plane mr-1"></span>
                youremail@email.com</a
              >
            </p> -->
          </div>
          <div class="col-md-6 d-flex justify-content-md-end">
            <div class="social-media">
              <p class="mb-0 d-flex">
                <a
                  href="#"
                  class="d-flex align-items-center justify-content-center"
                  ><span class="fa fa-facebook"
                    ><i class="sr-only">Facebook</i></span
                  ></a
                >
                <a
                  href="#"
                  class="d-flex align-items-center justify-content-center"
                  ><span class="fa fa-twitter"
                    ><i class="sr-only">Twitter</i></span
                  ></a
                >
                <a
                  href="#"
                  class="d-flex align-items-center justify-content-center"
                  ><span class="fa fa-instagram"
                    ><i class="sr-only">Instagram</i></span
                  ></a
                >
                <a
                  href="#"
                  class="d-flex align-items-center justify-content-center"
                  ><span class="fa fa-dribbble"
                    ><i class="sr-only">Dribbble</i></span
                  ></a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg navbar-custom py-0 ">
      <b-navbar-brand href="#" style="color: #ffefd5; font-size: 1rem">
        <img src="@/assets/images/Logo.jpeg" width="70px" />
      </b-navbar-brand>
      <b-navbar-toggle target="navbarCustom"
        ><i class="fa fa-bars fa-lg py-1 text-black"></i
      ></b-navbar-toggle>
      <b-collapse is-nav id="navbarCustom">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link class="nav-link marathi" to="/">मुख्य पृष्ट</router-link>
          </li>
          <!--<li class="nav-item">
            <router-link class="nav-link" to="/aboutus"
              >About Us</router-link
            >
          </li>-->
          <li class="nav-item">
            <router-link class="nav-link" to="/gallery"
              >उपक्रम</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/contactus"
              >संपर्क</router-link
            >
          </li>
        </ul>
      </b-collapse>
    </nav>
    <!-- </b-navbar> -->
    <b-row style="margin-top: 0px; max-height: 400px; background: #15569b">
      <b-col
        sm="12"
        md="6"
        style="text-align: left"
        class="marathi header-text d-none d-md-block ml-4"
      >
        <div class="icon d-flex align-items-center justify-content-center float-right" 
          style="background-color:white;margin-top:100px;">
          <img src="/Logo.jpeg" width="105px">
        </div>
        <p style="font-size: 3rem; color: white; margin-bottom: 0px">
          श्रीराम कुटे गुरुजी चॅरिटेबल ट्रस्ट
        </p>
        <p style="font-size: 2.5rem; color: white; margin-bottom: 0px">
          जळगाव-जामोद, बुलढाणा
        </p>
        <p style="font-size: 1.5rem; color: white; margin-bottom: 0px;margin-left:20px">
          <ul>
            <li>आरोग्य</li>
            <li>शिक्षण</li>
            <li>रोजगार</li>
          </ul>
        </p>
      </b-col>
      <b-col
        sm="12"
        md="6"
        style="text-align: center"
        class="marathi header-text d-block d-md-none"
      >
        
        <p style="font-size: 1.5rem; color: white; margin-bottom: 0px">
          श्रीराम कुटे गुरुजी चॅरिटेबल ट्रस्ट
        </p>
        <p style="font-size: 1.25rem; color: white; margin-bottom: 0px">
          जळगाव-जामोद, बुलढाणा
        </p>
        <p style="font-size: 1rem; color: white; margin-bottom: 0px">
          आरोग्य | शिक्षण | रोजगार
        </p>
      </b-col>
      <b-col>
        <b-carousel
          id="carousel-1"
          :interval="4000"
          controls
          indicators
          background="#15569b"
          img-height="80%"
          style="text-shadow: 1px 1px 2px #333"
          class="m-2"
        >
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid w-100"
                style="height: 80%; width: 100%"
                src="@/assets/images/image1.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid w-100"
                style="height: 80%; width: 100%"
                src="@/assets/images/image2.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>

          <b-carousel-slide>
            <template #img>
              <img
                class="d-block img-fluid w-100"
                style="height: 80%; width: 100%"
                src="@/assets/images/image3.jpg"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    logout() {
      this.$session.destroy();
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    login() {
      this.$router.replace("/");
    },
  },
  computed: {
    username() {
      var nme = this.$store.getters.loggedUser;
      return nme.user_name;
    },
    RoleID() {
      var role = this.$store.getters.regType;
      return role;
      //alert(nme.RegistrationType);
    },
    LockProfile() {
      var lck = this.$store.getters.lockProfile;
      return lck;
    },
    isLOI() {
      var LOIStatus = this.$store.getters.getAppStatus;
      if (
        LOIStatus == 6 ||
        LOIStatus == 8 ||
        LOIStatus == 9 ||
        LOIStatus == 21 ||
        LOIStatus == 22
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  background-color: #15569b;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  min-height: 25px;
}
.navbar-custom {
  background-color: white !important; /*#3c37f1 !important;*/
  min-height: 70px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 18px;
  line-height: 54px;
  font-family: "Open sans", sans-serif;
  text-transform: uppercase;
}
/* change the brand and text color */
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: black;
}
/* change the link color */
.navbar-custom .navbar-nav .nav-link {
  color: black;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 600;
  font-stretch: normal;
  font-size: 18px;
  font-family: "Open sans", sans-serif;
  padding-left: 30px;
  text-transform: uppercase;
}

/* change the color of active or hovered links */
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #e4762c; /*#f4ff60;*/
  padding-left: 30px;
  font-weight: 600;
}

.navbar-custom .dropdown-menu {
  background-color: white !important; /* #2e4b90 !important;*/
  margin-left: -50px;
}
.navbar-custom .dropdown-menu-left {
  background-color: #2e4b90 !important;
  margin-left: 10px !important;
}
.navbar-custom .dropdown-item {
  color: #ffffff;
  height: 40px !important;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 18px;
  font-family: "Open sans", sans-serif;
  line-height: 34px;
  padding-left: 30px;
  text-transform: uppercase;
}

.dropdown-item a {
  color: #ffffff;
  height: 40px !important;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;
  font-family: "Open sans", sans-serif;
  line-height: 34px;
  padding-left: 30px;
  text-transform: uppercase;
}
.navbar-custom .dropdown-item:hover,
.navbar-custom .dropdown-item:focus {
  /* color: #333333;
  background-color: rgba(255, 255, 255, 0.5); */
  color: black;
  background-color: white;
  display: block;
}

/* Dropdown Button */
.navbar-custom .dropbtn {
  height: 70px;
  background-color: #2e4b90 !important;
  color: white;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: 14px;

  font-family: "Open sans", sans-serif;
  border: none;
  padding-left: 30px;
  text-transform: uppercase;
}

/* The container <div> - needed to position the dropdown content */
.navbar-custom .dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #2e4b90;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn:hover {
  background-color: #f4ff60;
}

.header_aera .navbar-collapse .navbar-nav.navbar-right li a {
  margin-top: -15px;
}
.icon {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.icon span {
  font-size: 40px;
  color: black;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.dbox {
  width: 100%;
  margin-bottom: 25px;
}
</style>